import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import ActionCreator from "../../ActionCreator";
import { withPage } from "../../Page";
import ParseQuery from "../../Utils/ParseQuery";
import * as Widget2 from "../../Components/Widget2";

class ArticleDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: null,
      showSpinner: true
    };
  }

  componentDidMount() {
    let pathname = window.location.pathname;
    let articleId = ParseQuery(this.props.location.search).id;
    this._fetchRecords(pathname, articleId);
  }

  render() {
    let { article, showSpinner } = this.state;

    if (showSpinner) {
      return (
        <Widget2.Center>
          <Widget2.Spinner />
        </Widget2.Center>
      );
    }

    return (
      <Wrapper>
        <Container img={article.image}>
          <div className="image" />
          <div className="text">
            <div className="date">
              {moment(article.created).format("YYYY/MM/DD")}
            </div>
            <div className="title">{article.name}</div>
            <div className="content">{article.description}</div>
            <div
              className="content"
              dangerouslySetInnerHTML={this._createdMarkup(article.content)}
            />
          </div>
        </Container>
      </Wrapper>
    );
  }

  _fetchRecords = (pathname, id) => {
    let { appActions } = this.props;

    if (pathname.indexOf("blog") > 0) {
      return appActions
        .getBlog(id)
        .then(article => this.setState({ article }))
        .then(() => this.setState({ showSpinner: false }))
        .catch(err => {
          console.warn(err);
          this.setState({ showSpinner: false });
        });
    }

    if (pathname.indexOf("news") > 0) {
      return appActions
        .getNews(id)
        .then(article => this.setState({ article }))
        .then(() => this.setState({ showSpinner: false }))
        .catch(err => {
          console.warn(err);
          this.setState({ showSpinner: false });
        });
    }

    return 0;
  };

  _createdMarkup = content => {
    return { __html: content };
  };
}

const Wrapper = styled.div`
  padding-top: 140px;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1181px;
  padding: 20px;

  & > .image {
    margin-bottom: 27px;
    width: 100%;
    min-height: 580px;
    height: 100%;
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: 414px) {
      min-height: 400px;
    }
  }

  & > .text {
    margin: 0 auto;
    padding: 10px;
    max-width: 980px;
    width: 100%;

    & > .date {
      font-size: 14px;
      color: rgba(16, 29, 31, 0.6);
      letter-spacing: 1.4px;
    }

    & > .title {
      margin-bottom: 29px;
      font-weight: bold;
      font-size: 24px;
      color: #101d1f;
      letter-spacing: 2.4px;
    }

    & > .content {
      margin-bottom: 60px;
      white-space: pre-wrap;
      font-size: 16px;
      color: #101d1f;
      letter-spacing: 1.6px;
    }

    @media screen and (max-width: 1024px) {
      max-width: 800px;
    }

    @media screen and (max-width: 768px) {
      max-width: 600px;
    }
  }
`;

export default withPage(
  connect(
    null,
    ActionCreator
  )(ArticleDetailPage)
);
